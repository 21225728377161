import * as Turbo from '@hotwired/turbo'
import Alpine from 'alpinejs'
import anchor from "@alpinejs/anchor"
import {registerIconLibrary} from '@shoelace-style/shoelace/dist/utilities/icon-library.js'
import "@shoelace-style/shoelace/dist/components/icon/icon.js"

registerIconLibrary('bz', {
  resolver: name => `https://static-app-misc.banzai.org/icon-library/${name}.svg`
})

Alpine.plugin(anchor)
Alpine.start()
